<template>
    <div class="teaser component">
      <div
        class="cmp-teaser component"
       >
        <div
          class="cmp-teaser__container">
          <div class="cmp-teaser__content">
            <div class="cmp-teaser__tags">
                <div class="cmp-teaser__tag" v-for="(tag, index) in filteredTags" :key="index">
                  {{ tag }}
                </div>
            </div>
            <p class="cmp-teaser__pretitle" v-if="!!pretitle">{{pretitle}}</p>

            <h2 class="cmp-teaser__title">{{title}}</h2>

            <div class="cmp-teaser__description"><p>{{description}}</p></div>
            <a :href="cta.link" class="cmp-teaser__cta">{{cta.text}}</a>
          </div>

          <div class="cmp-teaser__image">
            <div
  
              class="cmp-adaptive-image cmp-image"
              itemscope=""
              itemtype="http://schema.org/ImageObject">
              <picture>
                <source media="(min-width: 1200px)" width="800" height="600" />
                <source width="800" height="600" />
                <img
                  :src="image.url"
                  loading="lazy"
                  class="cmp-image__image"
                  itemprop="contentUrl"
                  :alt="image.alt" />
              </picture>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import topLevelTags from './../../constants/topLevelTags';

export default {
  props: {
    title: String,
    pretitle: String,
    description: String,
    image: String,
    tags: Array,
    cta: Object,
    image: Object,
    filterTopLevel: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    filteredTags() {
      if (this.filterTopLevel) {
        return this.tags.filter(tag => !topLevelTags.includes(tag) && tag !== "Featured" && tag !== "Homepage" && tag !== "Screenreader");
      } else {
        return this.tags.filter(tag => tag !== "Featured" && tag !== "Homepage" && tag !== "Screenreader");
      }

    }
  }
};
</script>

<style scoped></style>

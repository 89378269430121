import { createApp, h } from 'vue';
import Search from './Search.vue';

const initSearch = () => {
  const searchContainer = document.querySelector('#search');

    const app = createApp({
      render: () => {
        return h(Search);
      },
    });


    app.mount(searchContainer);
};

if (document.readyState !== 'loading') {
  initSearch();
} else {
  document.addEventListener('DOMContentLoaded', initSearch);
}
<template>
  <div class="all-articles__filter-copy">
    <span> Filter articles by tag: </span>
  </div>
  <div v-if="tags && tags.length" class="all-articles__tags">
    <div class="all-articles__tag" :class="{
    'all-articles__tag--active': selectedTags.length === 0,
    'all-articles__tag--no-pointer': selectedTags.length === 0,
  }" @click="toggleAllTags">
      All
    </div>
    <div v-for="tag in tags" :key="tag" class="all-articles__tag"
      :class="{ 'all-articles__tag--active': selectedTags.includes(tag) }" @click="toggleTag(tag)">
      {{ tag }}
    </div>
  </div>
  <ArticleTeasers :articles="filteredArticles" :carousel-on-mobile="false" :force-three-up="true" />
</template>

<script>
import ArticleTeasers from './../article-teasers/ArticleTeasers.vue';
import { makeCloudSearchRequest } from './../../services/makeCloudSearchRequest';
import topLevelTags from '../../constants/topLevelTags';

export default {
  components: {
    ArticleTeasers,
  },
  data() {
    return {
      articles: [],
      selectedTags: [],
      tags: [],
    };
  },
  computed: {
    filteredArticles() {
      if (this.selectedTags.length === 0) {
        return this.articles;
      }
      return this.articles.filter((article) => {
        const articleTags = article.tags || [];
        return this.selectedTags.some((tag) => articleTags.includes(tag));
      });
    },
  },
  methods: {
    toggleTag(tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index > -1) {
        this.selectedTags.splice(index, 1);
      } else {
        this.selectedTags.push(tag);
      }
    },
    toggleAllTags() {
      if (this.selectedTags.length >= 0) {
        this.selectedTags = [];
      }
    },
  },
  mounted() {
    const keywords =
      document.querySelector('meta[name="keywords"]').content.split(',');

    makeCloudSearchRequest(keywords, 0, 1000, 'Article')
      .then((data) => {
        this.articles = data.hits.hit.map((hit) => ({
          ...hit,
          tags: hit.fields.keywords || [],
        }));

        // Filter out the tags that match any of the keywords
        const allKeywords = this.articles.reduce((acc, article) => {
          const filteredTags = article.tags.filter(
            (tag) => tag !== "Featured" && tag !== "Homepage" && tag !== "Screenreader" && !topLevelTags.includes(tag)
          );
          acc.push(...filteredTags);
          return acc;
        }, []);

        this.tags = [...new Set(allKeywords)];

      })
      .catch((error) => {
        console.error('Error fetching articles:', error);
      });
  },
};
</script>

<style scoped></style>

import { createApp, h } from 'vue';
import LatestArticles from './LatestArticles.vue';

const initLatestArticles = () => {
  const LatestArticlesContainer = document.querySelector('#latest-articles');
    const app = createApp({
      render: () => {
        return h(LatestArticles);
      },
    });


    app.mount(LatestArticlesContainer);
};

if (document.readyState !== 'loading') {
  initLatestArticles();
} else {
  document.addEventListener('DOMContentLoaded', initLatestArticles);
}
const loadHeader = () => {
  const header = document.querySelector('.header');

  if (!header) return;

  const container = header.querySelector('.cmp-container');
  let mobileVisible = false;

  // Reload page if accessing from back button to precent the hamburger menu from being stuck open
  window.addEventListener('pageshow', function (event) {
    var historyTraversal =
      event.persisted ||
      (typeof window.performance != 'undefined' &&
        window.performance.navigation.type === 2);
    if (historyTraversal && mobileVisible) {
      toggleMenu();
    }
  });

  // Creates the hamburger menu
  const hamburgerButton = document.createElement('button');

  hamburgerButton.className = 'header__hamburger-button';
  hamburgerButton.innerHTML = `
      <div class="header__hamburger-bar"></div>
      <div class="header__hamburger-bar"></div>
      <div class="header__hamburger-bar"></div>
    `;
  const hamburgerMenu = header.querySelector('.header__navigation');

  if (!hamburgerMenu) return;

  hamburgerMenu.id = 'hamburger-menu';

  container.appendChild(hamburgerButton);

  const closeButton = document.createElement('button');
  closeButton.className = 'header__close-button';
  closeButton.innerHTML = '&#xE909;';

  hamburgerMenu.appendChild(closeButton);

  const toggleMenu = () => {
    const headerNavigation = document.querySelector('.header__navigation');
    if (mobileVisible) {
      hamburgerButton.setAttribute('aria-expanded', 'false');
      headerNavigation.classList.remove('header__navigation-visible');
      hamburgerButton.focus();
    } else {
      hamburgerButton.setAttribute('aria-expanded', 'true');
      headerNavigation.classList.add('header__navigation-visible');
      closeButton.focus();
    }
    mobileVisible = !mobileVisible;
  };

  const handleToggle = () => {
    toggleMenu();
  };

  hamburgerButton.addEventListener('click', handleToggle);
  closeButton.addEventListener('click', handleToggle);

  const resetMenu = () => {
    const headerNavigation = document.querySelector('.header__navigation');

    headerNavigation.classList.remove('header__navigation-visible');

    if (window.innerWidth < 768) {
      hamburgerButton.setAttribute('aria-controls', `hamburger-menu`);
      hamburgerButton.setAttribute('aria-expanded', 'false');
    } else {
      hamburgerButton.removeAttribute('aria-expanded');
      hamburgerButton.removeAttribute('aria-controls');
    }
  };

  const handleResize = () => {
    if (mobileVisible) {
      toggleMenu();
    }
    resetMenu();
  };

  resetMenu();

  window.addEventListener('keyup', (event) => {
    if (event.key === 'Escape') {
      toggleMenu();
    }
  });

  // Trap focus within the menu when it's open
  hamburgerMenu.addEventListener('keydown', (event) => {
    if (mobileVisible && event.key === 'Tab') {
      const focusableElements = Array.from(
        hamburgerMenu.querySelectorAll('a, button')
      );
      const firstFocusable = focusableElements.find(
        (element) => element.tabIndex !== -1
      );
      const lastFocusable = focusableElements[focusableElements.length - 1];
      if (event.shiftKey && document.activeElement === firstFocusable) {
        closeButton.focus();
        event.preventDefault();
      } else if (!event.shiftKey && document.activeElement === lastFocusable) {
        firstFocusable.focus();
        event.preventDefault();
      }
    }
  });

  window.addEventListener('resize', handleResize);

  // Adding class "selected" to the link matching the canonical URL
  const canonicalURL = document
    .querySelector('link[rel="canonical"]')
    .getAttribute('href');
  const menuLinks = header.querySelectorAll('.header__navigation a');
  menuLinks.forEach((link) => {
    if (link.getAttribute('href').includes(canonicalURL)) {
      link.classList.add('selected');
      link.setAttribute('tabindex', '-1');
    }
  });
};

if (document.readyState !== 'loading') {
  loadHeader();
} else {
  document.addEventListener('DOMContentLoaded', loadHeader);
}

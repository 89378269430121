<template>
  <div class="search-input">
    <div class="search-input__wrapper">
      <form @submit.prevent="performSearch">
        <input
          class="search-input__input"
          v-model="searchQuery"
          aria-label="search"
          type="text"
          :placeholder="placeholderText"
        />
        <button
          class="search-input__button"
          :disabled="!searchQuery.trim()"
          :tabindex="!searchQuery.trim() ? -1 : 0"
          @click="performSearch"
        >
          <span class="sr-only">Search</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: "",
      placeholderText: window.innerWidth < 550 ? "Search" : "What can we help you find?",
    };
  },
  methods: {
    updatePlaceholderText() {
      this.placeholderText = window.innerWidth < 550 ? "Search" : "What can we help you find?";
    },
    performSearch() {
      if (this.searchQuery.trim()) {
        window.location = `/search?q=${encodeURIComponent(this.searchQuery.trim())}`;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.updatePlaceholderText);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updatePlaceholderText);
  },
};
</script>

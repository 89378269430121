import { createApp, h } from 'vue';
import SearchInput from './SearchInput.vue';

const initSearchInput = () => {
  const searchInputContainer = document.querySelector('#search-input');

    const app = createApp({
      render: () => {
        return h(SearchInput);
      },
    });

    app.mount(searchInputContainer);
};

if (document.readyState !== 'loading') {
  initSearchInput();
} else {
  document.addEventListener('DOMContentLoaded', initSearchInput);
}
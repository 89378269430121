export async function makeCloudSearchRequest(
  keywords,
  start = 0,
  size = 3,
  contentType,
  andQuery = false,
  textSearch = ''
) {
  let isScreenreader = false;
  let filteredKeywords = [];

  // In the case of a screenreader tag, we add an "and" query for the screenreader tag
  if (keywords.includes('Screenreader')) {
      isScreenreader = true;
      filteredKeywords = keywords.filter(keyword => keyword !== 'Screenreader');
  } else {
      isScreenreader = false;
      filteredKeywords = [...keywords];
  }

  const queryKeywords = filteredKeywords
    .map((keyword) => `keywords:+${`%27${keyword.replace(/ /g, '+')}%27`}`)
    .join('+');

  const searchResultsElement = document.getElementById('searchResult');
  if (!searchResultsElement) {
    throw new Error('searchResults element not found');
  }

  const searchResultsDataAttr =
    searchResultsElement.getAttribute('data-params');
  if (!searchResultsDataAttr) {
    throw new Error(
      "'data-params' attribute not found in searchResults element"
    );
  }

  const searchResultsData = JSON.parse(searchResultsDataAttr);
  const baseUrl = searchResultsData.searchApiPrefix;
  if (!baseUrl) {
    throw new Error(
      "Base URL (searchApiPrefix) not found in the 'data-params' attribute of searchResults element"
    );
  }

  const constructQueryString = (params) => {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`.replace(/&/g, '%26'))
      .join('&');
  };

const queryParams = andQuery
  ? {
      q: `(and ${queryKeywords} ${
        isScreenreader
          ? "keywords:+%27Screenreader%27 "
          : "(not%20keywords:+%27Screenreader%27) "
      }content_type:%27${contentType.replace(/ /g, "+")}%27${
        textSearch ? ` '${encodeURIComponent(textSearch)}'` : ""
      })`,
      "q.parser": "structured",
      sort: "publication_date+desc",
      fq: `site:${encodeURIComponent(`'${window.tccc.cloudsearch}'`)}`,
      start: start,
      size: size,
    }
  : {
      q: `(and (or ${queryKeywords}) ${
        isScreenreader
          ? "keywords:+%27Screenreader%27 "
          : "(not%20keywords:+%27Screenreader%27) "
      }content_type:%27${contentType.replace(/ /g, "+")}%27${
        textSearch ? ` '${encodeURIComponent(textSearch)}'` : ""
      })`,
      "q.parser": "structured",
      sort: "publication_date+desc",
      fq: `site:${encodeURIComponent(`'${window.tccc.cloudsearch}'`)}`,
      start: start,
      size: size,
    };

  
  const queryString = constructQueryString(queryParams);
  const url = `${baseUrl}/api/search?${queryString}`;


  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error making CloudSearch request:', error);
    throw error;
  }
}

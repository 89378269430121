<template>
<div class="search">
  <div class="search__input-wrapper">
    <input class="search__input" type="text" @input="searchPerformed = false" aria-label="Search Query" :placeholder="placeholderText"
      v-model="searchQuery" @keyup.enter="performSearch" />
    <button class="search__button" v-if="!searchPerformed" @click="performSearch">
      <span class="sr-only">Search</span>
    </button>
    <button class="search__button search__button-close" v-if="searchPerformed" @click="clearSearch">
      <span class="sr-only">Clear Search</span>
    </button>
  </div>
</div>
<div class="search__results" v-if="results?.length">
  <h3>Showing {{ totalResults }} results</h3>
  <div v-for="(result, index) in results" :key="index">
    <a class="search__result" :href="result.fields.path">
      <img :src="result.fields.thumbnail_image" :alt="result.fields.thumbnail_alt_text || 'Image'" />
      <div class="search__result-info">
        <div class="search__info-tags">
          <span class="search__result-info-tag" v-for="(tag, index) in result.fields.keywords" :key="index">
            {{ tag }}
          </span>
        </div>
        <h3>{{ result.fields.title }}</h3>
        <p>{{ result.fields.description }}</p>
      </div>
    </a>
  </div>
  <div class="search__results-info">
    <p class="search__results-info-pages">
      Showing page {{ currentPage + 1 }} of {{ totalPages }}
    </p>
    <div class="search__results-info-paginator">
      <button class="search__results-info-paginator-prev" v-if="currentPage > 0" @click="changePage(currentPage - 1)">
        <span class="sr-only">Prev</span>
      </button>
      <button class="search__results-info-paginator-page" v-for="page in pagesToShow" :key="page"
        @click="changePage(page)" :class="{ active: currentPage === page }">
        {{ page + 1 }}
      </button>
      <template v-if="pagesToShow[pagesToShow.length - 1] < totalPages - 1">
        <span class="search__results-info-paginator-ellipsis">...</span>
        <button class="search__results-info-paginator-page" @click="changePage(totalPages - 1)"
          :class="{ active: currentPage === totalPages - 1 }">
          {{ totalPages }}
        </button>
      </template>
      <button class="search__results-info-paginator-next" v-if="currentPage < totalPages - 1"
        @click="changePage(currentPage + 1)">
        <span class="sr-only">Next</span>
      </button>
    </div>
  </div>
</div>
<div class="search__results" v-if="noResults">
  <h3>Showing {{ totalResults }} results</h3>
  <h2 class="search__no-results-headline">
    Hmm, looks like we couldn’t find any results.
  </h2>
  <p class="search__no-results-copy">
    Try entering another query, or explore our latest articles below.
  </p>
</div>
</template>

<script>
import { makeCloudSearchRequest } from "../../services/makeCloudSearchRequest";

export default {
  data() {
    return {
      searchQuery: "",
      results: [],
      noResults: false,
      totalResults: 0,
      searchPerformed: false,
      currentPage: 0,
      itemsPerPage: 10,
      placeholderText: window.innerWidth < 500 ? "Search" : "What can we help you find?",
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalResults / this.itemsPerPage);
    },
    pagesToShow() {
      const total = this.totalPages;
      const current = this.currentPage;
      const maxVisible = 5;
      if (total <= maxVisible) {
        return Array.from({ length: total }, (_, i) => i);
      }
      const range = [];
      const start = Math.max(0, current - Math.floor(maxVisible / 2));
      const end = Math.min(total - 1, start + maxVisible - 1);
      for (let i = start; i <= end; i++) {
        range.push(i);
      }
      while (range.length < maxVisible && range[0] > 0) {
        range.unshift(range[0] - 1);
      }
      return range;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updatePlaceholderText);
    const urlParams = new URLSearchParams(window.location.search);
    this.searchQuery = urlParams.get('q') ?? null;
    if (this.searchQuery) {
      this.performSearch();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updatePlaceholderText);
  },
  methods: {
    clearSearch() {
      this.results = [];
      this.searchQuery = null;
      this.searchPerformed = false;
      this.noResults = false;
    },
    performSearch(startIndex = 0, rangeSize = this.itemsPerPage) {
      this.noResults = false;
      if (isNaN(startIndex)) startIndex = 0;
      if (isNaN(rangeSize)) rangeSize = this.itemsPerPage;
      if (this.searchQuery.trim()) {
        const url = new URL(window.location);
        url.searchParams.set("q", this.searchQuery.trim());
        window.history.replaceState({}, "", url);

        makeCloudSearchRequest([], startIndex, rangeSize, "Article", true, this.searchQuery)
          .then((data) => {
            this.results = data.hits.hit;
            this.totalResults = data.hits.found;
            this.currentPage = Math.floor(startIndex / rangeSize);
            this.searchPerformed = true;
            this.noResults = !data.hits.hit.length;
          })
          .catch((error) => {
            console.error("Error fetching articles:", error);
          });
      }
    },
    changePage(page) {
      if (page >= 0 && page < this.totalPages) {
        const startIndex = page * this.itemsPerPage;
        const rangeSize = this.itemsPerPage;
        this.performSearch(startIndex, rangeSize);
      }
    },
    updatePlaceholderText() {
      this.placeholderText = window.innerWidth < 500 ? "Search" : "What can we help you find?";
    },
  },
};
</script>
